import {
  DrawerClose,
  DrawerContent,
  DrawerOverlay,
  DrawerPortal,
  DrawerRoot,
} from '@paid-ui/components/drawer';
import { LoadingIndicator } from '@paid-ui/components/loading-indicator';
import { ClaimAction, ClaimMethod, ClaimType } from '@paid-ui/enums/claim';
import { ContractActionType, ContractTemplate, ContractType } from '@paid-ui/enums/contract';
import { VariationActionType } from '@paid-ui/enums/variation';
import { featureManager } from '@paid-ui/models/feature';
import { useSearchParams } from 'next/navigation';
import { useRouter } from 'next/router';
import { forwardRef, lazy as dynamic, Suspense, useEffect, useRef } from 'react';
import { useSnapshot } from 'valtio/react';

import { closeFlyout, openFlyout, restoreFlyout } from './actions';
import { flyoutManager, FlyoutType } from './model';

const ApplyAccountForm = dynamic(() => import('@paid-ui/forms/apply-account'));
const AccountTransfer = dynamic(() => import('@paid-ui/forms/account-transfer'));
const AssignFundsForm = dynamic(() => import('@paid-ui/forms/assign-funds'));
const TransactionDetails = dynamic(() => import('@paid-ui/blocks/transaction-details'));
const CreateProjectForm = dynamic(() => import('@paid-ui/forms/create-project'));
const EditProjectForm = dynamic(() => import('@paid-ui/forms/edit-project'));
const UpdateProjectStatus = dynamic(() => import('@paid-ui/forms/update-project-status'));
const DeleteProject = dynamic(() => import('@paid-ui/forms/delete-project'));
const JoinContractForm = dynamic(() => import('@paid-ui/forms/join-contract'));
const PrimeContractForm = dynamic(() => import('@paid-ui/forms/prime-contract'));
const SecondaryContractForm = dynamic(() => import('@paid-ui/forms/secondary-contract'));
const SignContract = dynamic(() => import('@paid-ui/blocks/sign-contract'));
const AbandonContract = dynamic(() => import('@paid-ui/blocks/abandon/AbandonContract'));
const RevokeContractForm = dynamic(() => import('@paid-ui/forms/revoke-contract'));
const ResendInviteForm = dynamic(() => import('@paid-ui/forms/resend-invite'));
const ContractDetails = dynamic(() => import('@paid-ui/blocks/contract-details'));
const ContractNavigator = dynamic(() => import('@paid-ui/blocks/contract-navigator'));
const SetCommencementDateForm = dynamic(() => import('@paid-ui/forms/set-commencement-date'));
const SetCompletionDateForm = dynamic(() => import('@paid-ui/forms/set-completion-date'));
const LinkedPayments = dynamic(() => import('@paid-ui/blocks/linked-payments'));
const ContractDocuments = dynamic(() => import('@paid-ui/blocks/contract-documents'));
const ContractForms = dynamic(() => import('@paid-ui/blocks/contract-forms'));
const PaymentDetails = dynamic(() => import('@paid-ui/blocks/payment-details'));
const UpdateConsolidatedClaimForm = dynamic(
  () => import('@paid-ui/forms/update-consolidated-claim'),
);
const PayClaimForm = dynamic(() => import('@paid-ui/forms/pay-claim'));
const AllClaims = dynamic(() => import('@paid-ui/blocks/all-claims'));
const ClaimDetails = dynamic(() => import('@paid-ui/blocks/claim-details'));
const PeriodicVariationForm = dynamic(() => import('@paid-ui/forms/periodic-variation'));
const AbandonVariation = dynamic(() => import('@paid-ui/blocks/abandon/AbandonVariation'));
const VariationDetails = dynamic(() => import('@paid-ui/blocks/variation-details'));
const AbandonAdjustment = dynamic(() => import('@paid-ui/blocks/abandon/AbandonAdjustment'));
const AllVariations = dynamic(() => import('@paid-ui/blocks/all-variations'));
const AdjustmentDetails = dynamic(() => import('@paid-ui/blocks/adjustment-details'));

const SecurePayment = dynamic(() => import('@paid-ui/blocks/secure-funds/secure-payment'));
const SecureVariation = dynamic(() => import('@paid-ui/blocks/secure-funds/secure-variation'));
const EarlyReleaseForm = dynamic(() => import('@paid-ui/forms/early-release'));

const SecurePaid = dynamic(() => import('@paid-ui/forms/secure-paid'));
const UnsecurePaid = dynamic(() => import('@paid-ui/forms/unsecure-paid'));

const HelpCenterForm = dynamic(() => import('@paid-ui/forms/help-center'));
const CheckoutForm = dynamic(() => import('@paid-ui/forms/checkout'));
const NewLinkingForm = dynamic(() => import('@paid-ui/blocks/new-linking'));
const SupercontractForm = dynamic(() => import('@paid-ui/forms/supercontract'));
const SuperclaimForm = dynamic(() => import('@paid-ui/forms/superclaim'));
const EditPersonalForm = dynamic(() => import('@paid-ui/forms/edit-personal'));
const EditBusinessForm = dynamic(() => import('@paid-ui/forms/edit-business'));
const EditTrusteesForm = dynamic(() => import('@paid-ui/forms/edit-trustees'));
const EditIndividualTrustees = dynamic(() => import('@paid-ui/forms/edit-individual-trustees'));
const EditSignatoryForm = dynamic(() => import('@paid-ui/forms/edit-signatory'));
const EditPermissionsForm = dynamic(() => import('@paid-ui/forms/edit-permissions'));
const InviteUserForm = dynamic(() => import('@paid-ui/forms/invite-user'));
const ProjectGuide = dynamic(() => import('@paid-ui/blocks/project-guide'));

const AccountDetails = dynamic(() => import('@paid-ui/blocks/account-details'));
const DepositRefundForm = dynamic(() => import('@paid-ui/forms/deposit-refund'));
const TerminateContractForm = dynamic(() => import('@paid-ui/forms/terminate-contract'));

type Props = {
  type: FlyoutType;
  details: Record<string, any>;
};

const upper = (qs: string | string[] | undefined) => {
  const q = Array.isArray(qs) ? qs[0] : qs;
  return q ? q.toUpperCase() : '';
};

const preventDefault = (event: Event) => event.preventDefault();

const DefaultGlobalFlyoutContent: React.FC<{ type: FlyoutType }> = ({ type }) => {
  switch (type) {
    case FlyoutType.EDIT_PERSONAL_INFO: {
      return <EditPersonalForm />;
    }
    case FlyoutType.EDIT_BUSINESS_INFO: {
      return <EditBusinessForm />;
    }
    case FlyoutType.EDIT_TRUSTEES_INFO: {
      return <EditTrusteesForm />;
    }
    case FlyoutType.EDIT_INDIVIDUAL_TRUSTEES: {
      return <EditIndividualTrustees />;
    }
    case FlyoutType.APPLY_ACCOUNT: {
      return <ApplyAccountForm />;
    }
    case FlyoutType.CREATE_PROJECT: {
      return <CreateProjectForm />;
    }
    case FlyoutType.EDIT_PROJECT: {
      return <EditProjectForm />;
    }
    case FlyoutType.RESEND_INVITE: {
      return <ResendInviteForm />;
    }
    case FlyoutType.ALL_CLAIMS: {
      return <AllClaims />;
    }
    case FlyoutType.ALL_VARIATIONS: {
      return <AllVariations />;
    }
    case FlyoutType.HELP_CENTER: {
      return <HelpCenterForm />;
    }
    case FlyoutType.ACCOUNT_TRANSFER: {
      return <AccountTransfer />;
    }
    case FlyoutType.ABANDON_CONTRACT: {
      return <AbandonContract />;
    }
    case FlyoutType.ABANDON_VARIATION: {
      return <AbandonVariation />;
    }
    case FlyoutType.ABANDON_ADJUSTMENT: {
      return <AbandonAdjustment />;
    }
    case FlyoutType.ARCHIVE_PROJECT: {
      return <UpdateProjectStatus />;
    }
    case FlyoutType.DELETE_PROJECT: {
      return <DeleteProject />;
    }
    case FlyoutType.SET_COMMENCEMENT_DATE: {
      return <SetCommencementDateForm />;
    }
    case FlyoutType.SET_COMPLETION_DATE: {
      return <SetCompletionDateForm />;
    }
    case FlyoutType.NEW_LINKING: {
      return <NewLinkingForm />;
    }
    default: {
      return null;
    }
  }
};

const GlobalFlyoutContent: React.FC<Props> = ({ type, details }) => {
  switch (type) {
    case FlyoutType.PROJECT_GUIDE: {
      const { isEmpty, hidden, viewAs } = details ?? {};
      return <ProjectGuide isEmpty={isEmpty} viewAs={viewAs} hidden={hidden} />;
    }
    case FlyoutType.EDIT_SIGNATORY: {
      const { relation } = details ?? {};
      return <EditSignatoryForm relation={relation} />;
    }
    case FlyoutType.INVITE_USER: {
      const { userGroupId } = details ?? {};
      return <InviteUserForm userGroupId={userGroupId} />;
    }
    case FlyoutType.EDIT_PERMISSIONS: {
      const { userGroupId, userId } = details ?? {};
      return <EditPermissionsForm userGroupId={userGroupId} userId={userId} />;
    }
    case FlyoutType.TRANSACTION_DETAILS: {
      const { transactionId, subTab = 'overview' } = details ?? {};
      return <TransactionDetails transactionId={transactionId} defaultTab={subTab} />;
    }
    case FlyoutType.CREATE_CONTRACT: {
      const { contractType } = details ?? {};
      switch (contractType) {
        case ContractType.HEAD_CONTRACT: {
          return <PrimeContractForm actionType={ContractActionType.CREATE} />;
        }
        default: {
          return (
            <SecondaryContractForm
              actionType={ContractActionType.CREATE}
              contractType={contractType}
            />
          );
        }
      }
    }
    case FlyoutType.REVOKE_CONTRACT: {
      return <RevokeContractForm />;
    }
    case FlyoutType.SUPER_CONTRACT: {
      const { contractId, isDraft, isReview, contractType } = details;
      return (
        <SupercontractForm
          contractId={contractId}
          isReview={isReview}
          isDraft={isDraft}
          contractType={contractType}
        />
      );
    }
    case FlyoutType.SUPER_CLAIM: {
      const { action, paymentId, claimId, type } = details;
      return <SuperclaimForm action={action} paymentId={paymentId} claimId={claimId} type={type} />;
    }
    case FlyoutType.AMEND_CONTRACT: {
      const { contractType } = details ?? {};
      switch (contractType) {
        case ContractType.HEAD_CONTRACT: {
          return <PrimeContractForm actionType={ContractActionType.AMEND} />;
        }
        default: {
          return (
            <SecondaryContractForm
              actionType={ContractActionType.AMEND}
              contractType={contractType}
            />
          );
        }
      }
    }
    case FlyoutType.JOIN_CONTRACT: {
      const { contractId, partyId } = details;
      return <JoinContractForm contractId={contractId} partyId={partyId} />;
    }
    case FlyoutType.REVIEW_CONTRACT: {
      const { contractType } = details ?? {};
      switch (contractType) {
        case ContractType.HEAD_CONTRACT: {
          return <PrimeContractForm actionType={ContractActionType.REVIEW} />;
        }
        default: {
          return (
            <SecondaryContractForm
              actionType={ContractActionType.REVIEW}
              contractType={contractType}
            />
          );
        }
      }
    }
    case FlyoutType.CONTRACT_DETAILS: {
      const { contractId, subTab = 'overview' } = details ?? {};
      return <ContractDetails contractId={contractId} defaultTab={subTab} />;
    }
    case FlyoutType.SIGN_CONTRACT: {
      const { contractId } = details ?? {};
      return <SignContract contractId={contractId} />;
    }
    case FlyoutType.CONTRACT_NAVIGATOR: {
      const { contractId } = details ?? {};
      return <ContractNavigator contractId={contractId} />;
    }
    case FlyoutType.ACCOUNT_DETAILS: {
      const { contractId, subTab = 'overview' } = details ?? {};
      return <AccountDetails contractId={contractId} defaultTab={subTab} />;
    }
    case FlyoutType.DEPOSIT_REFUND: {
      const { contractId, requestId } = details ?? {};
      return <DepositRefundForm contractId={contractId} requestId={requestId} />;
    }
    case FlyoutType.CONTRACT_TERMINATE: {
      const { contractId, requestId } = details ?? {};
      return <TerminateContractForm contractId={contractId} requestId={requestId} />;
    }
    case FlyoutType.UPDATE_CLAIM: {
      const { claimId } = details ?? {};
      return <UpdateConsolidatedClaimForm claimId={claimId} />;
    }
    case FlyoutType.EARLY_RELEASE: {
      const { claimId, isReview } = details ?? {};
      return <EarlyReleaseForm claimId={claimId} isReview={isReview} />;
    }
    case FlyoutType.PAY_CLAIM: {
      const { claimId } = details ?? {};
      return <PayClaimForm claimId={claimId} />;
    }
    case FlyoutType.LINKED_PAYMENTS: {
      const { paymentId } = details ?? {};
      return <LinkedPayments paymentId={paymentId} />;
    }
    case FlyoutType.PAYMENT_DETAILS: {
      const { paymentId, subTab = 'overview' } = details ?? {};
      return <PaymentDetails paymentId={paymentId} defaultTab={subTab} />;
    }
    case FlyoutType.CLAIM_DETAILS: {
      const { claimId, subTab = 'overview' } = details ?? {};
      return <ClaimDetails claimId={claimId} defaultTab={subTab} />;
    }
    case FlyoutType.NEW_VARIATION: {
      return <PeriodicVariationForm actionType={VariationActionType.CREATE} />;
    }
    case FlyoutType.AMEND_VARIATION: {
      const { variationId } = details ?? {};
      return (
        <PeriodicVariationForm actionType={VariationActionType.AMEND} variationId={variationId} />
      );
    }
    case FlyoutType.REVIEW_VARIATION: {
      const { variationId } = details ?? {};
      return (
        <PeriodicVariationForm actionType={VariationActionType.REVIEW} variationId={variationId} />
      );
    }
    case FlyoutType.VARIATION_DETAILS: {
      const { variationId, subTab = 'overview' } = details ?? {};
      return <VariationDetails variationId={variationId} defaultTab={subTab} />;
    }
    case FlyoutType.ADJUSTMENT_DETAILS: {
      const { adjustmentId, subTab = 'overview' } = details ?? {};
      return <AdjustmentDetails adjustmentId={adjustmentId} defaultTab={subTab} />;
    }
    case FlyoutType.SECURE_PAYMENT: {
      const { paymentId, claimId } = details ?? {};
      return <SecurePayment paymentId={paymentId} claimId={claimId} />;
    }
    case FlyoutType.SECURE_VARIATION: {
      const { variationId } = details ?? {};
      return <SecureVariation variationId={variationId} />;
    }
    case FlyoutType.ASSIGN_FUNDS: {
      const { subTab, data } = details ?? {};
      return <AssignFundsForm defaultTab={subTab} data={data} />;
    }
    case FlyoutType.SECURE_PAID_PRELOAD: {
      const { contractId, requestId } = details ?? {};
      return <SecurePaid contractId={contractId} requestId={requestId} />;
    }
    case FlyoutType.UNSECURE_PAID_PRELOAD: {
      const { contractId, requestId } = details ?? {};
      return <UnsecurePaid contractId={contractId} requestId={requestId} />;
    }
    case FlyoutType.CHECKOUT: {
      const {
        product,
        payload,
        submitText,
        submittingText,
        isSubmitting,
        onSessionReady,
        onCheckoutFail,
        onCheckoutSuccess,
      } = details ?? {};
      return (
        <CheckoutForm
          product={product}
          payload={payload}
          submitText={submitText}
          submittingText={submittingText}
          isSubmitting={isSubmitting}
          onSessionReady={onSessionReady}
          onCheckoutFail={onCheckoutFail}
          onCheckoutSuccess={onCheckoutSuccess}
        />
      );
    }
    case FlyoutType.DOCUMENTS: {
      const { subTab } = details ?? {};
      return <ContractDocuments defaultTab={subTab} />;
    }
    case FlyoutType.FORMS: {
      return <ContractForms />;
    }
    default: {
      return <DefaultGlobalFlyoutContent type={type} />;
    }
  }
};

export const GlobalFlyout = forwardRef(() => {
  const { enableOnPlatformRetention } = useSnapshot(featureManager);
  const {
    open,
    nestedOpen,
    type,
    details,
    stack,
    showCloseIcon,
    closeOnClickOutside,
    closeOnEscapeKeyDown,
    className,
    closeCallback,
  } = useSnapshot(flyoutManager);
  const router = useRouter();
  const searchParams = useSearchParams();
  const dialog = searchParams.get('dialog');
  const mountedRef = useRef(false);

  const handleFlyoutClose = () => {
    if (stack.length > 0) {
      restoreFlyout();
    } else {
      closeFlyout();
    }
    setTimeout(closeCallback, 150);
  };

  useEffect(() => {
    switch (dialog) {
      case 'edit-personal': {
        openFlyout(FlyoutType.EDIT_PERSONAL_INFO, {
          className: 'w-[752px]',
        });
        break;
      }
      case 'edit-business': {
        openFlyout(FlyoutType.EDIT_BUSINESS_INFO, {
          className: 'w-[752px]',
        });
        break;
      }
      case 'edit-trustees': {
        openFlyout(FlyoutType.EDIT_TRUSTEES_INFO, {
          className: 'w-[752px]',
        });
        break;
      }
      case 'edit-individual-trustees': {
        openFlyout(FlyoutType.EDIT_INDIVIDUAL_TRUSTEES, {
          className: 'w-[752px]',
        });
        break;
      }
      case 'edit-signatory': {
        const relation = router.query.relation;
        openFlyout(FlyoutType.EDIT_SIGNATORY, {
          className: 'w-[752px]',
          details: {
            relation,
          },
        });
        break;
      }
      case 'apply-account': {
        openFlyout(FlyoutType.APPLY_ACCOUNT);
        break;
      }
      case 'transaction-details': {
        const transactionId = router.query.transactionId || router.query.transaction;
        if (!transactionId) return;
        openFlyout(FlyoutType.TRANSACTION_DETAILS, {
          className: 'w-[752px]',
          closeOnClickOutside: true,
          details: {
            transactionId,
            subTab: router.query.subTab,
          },
        });
        break;
      }
      case 'new-project': {
        openFlyout(FlyoutType.CREATE_PROJECT);
        break;
      }
      case 'edit-project': {
        openFlyout(FlyoutType.EDIT_PROJECT);
        break;
      }
      case 'complete-contract':
      case 'join-contract': {
        const contractId = searchParams.get('contractId');
        const partyId = searchParams.get('partyId');
        if (!contractId || !partyId) return;

        openFlyout(FlyoutType.JOIN_CONTRACT, {
          details: {
            contractId,
            partyId,
          },
        });
        break;
      }
      case 'new-super-contract': {
        const contractType = searchParams.getAll('contractType');
        const isDraft = router.query.draft === '1';
        openFlyout(FlyoutType.SUPER_CONTRACT, {
          closeOnClickOutside: false,
          details: {
            contractType,
            contractId: isDraft ? router.query.contractId : '',
            isDraft: router.query.draft === '1',
          },
        });
        break;
      }
      case 'new-contract': {
        if (searchParams.has('template') || searchParams.has('method')) return;
        openFlyout(FlyoutType.CREATE_CONTRACT, {
          details: {
            contractType: ContractType.HEAD_CONTRACT,
          },
        });
        break;
      }
      case 'new-subcontract': {
        if (searchParams.has('template') || searchParams.has('method')) return;
        openFlyout(FlyoutType.CREATE_CONTRACT, {
          details: {
            contractType: ContractType.SUBCONTRACT,
          },
        });
        break;
      }
      case 'new-supply-contract': {
        if (searchParams.has('template') || searchParams.has('method')) return;
        openFlyout(FlyoutType.CREATE_CONTRACT, {
          details: {
            contractType: ContractType.SUPPLY_CONTRACT,
          },
        });
        break;
      }
      case 'amend-contract': {
        if (upper(router.query.method) !== ClaimMethod.PERIODIC) return;
        openFlyout(FlyoutType.AMEND_CONTRACT, {
          details: {
            contractType:
              typeof router.query.contractType === 'string'
                ? router.query.contractType.toUpperCase()
                : ContractType.HEAD_CONTRACT,
          },
        });
        break;
      }
      case 'review-super-contract': {
        openFlyout(FlyoutType.SUPER_CONTRACT, {
          closeOnClickOutside: false,
          details: {
            contractId: router.query.contractId,
            isDraft: router.query.draft === '1',
            isReview: true,
          },
        });
        break;
      }
      case 'review-contract': {
        if (upper(router.query.template) === ContractTemplate.SUPER_CONTRACT) {
          openFlyout(FlyoutType.SUPER_CONTRACT, {
            closeOnClickOutside: false,
            details: {
              contractId: router.query.contractId,
              isDraft: router.query.draft === '1',
              isReview: true,
            },
          });
          return;
        }

        if (
          upper(router.query.method) !== ClaimMethod.PERIODIC &&
          !upper(router.query.template).includes('RBN')
        ) {
          return;
        }

        openFlyout(FlyoutType.REVIEW_CONTRACT, {
          details: {
            contractType:
              typeof router.query.contractType === 'string'
                ? router.query.contractType.toUpperCase()
                : ContractType.HEAD_CONTRACT,
          },
        });
        break;
      }
      case 'sign-contract': {
        const contractId = router.query.contractId || router.query.contract;
        const contractTemplate = router.query.contractTemplate;
        if (!contractId) return;
        if (contractTemplate !== ContractTemplate.SUPER_CONTRACT) return;
        openFlyout(FlyoutType.SIGN_CONTRACT, {
          details: {
            contractId,
          },
        });
        break;
      }
      case 'reinvite-superintendent':
      case 'reinvite-architect':
      case 'resend-invite': {
        openFlyout(FlyoutType.RESEND_INVITE, {
          className: 'w-[752px]',
          showCloseIcon: true,
        });
        break;
      }
      case 'contract-details': {
        const contractId = router.query.contractId || router.query.contract;
        if (!contractId) return;
        openFlyout(FlyoutType.CONTRACT_DETAILS, {
          className: 'w-[752px]',
          closeOnClickOutside: true,
          details: {
            contractId,
            subTab: router.query.subTab,
          },
        });
        break;
      }
      case 'contract-navigator': {
        const contractId = router.query.contractId || router.query.contract;
        if (!contractId) return;
        openFlyout(FlyoutType.CONTRACT_NAVIGATOR, {
          details: {
            contractId,
          },
        });
        break;
      }
      case 'account-details': {
        const contractId = router.query.contractId || router.query.contract;
        if (!contractId) return;
        openFlyout(FlyoutType.ACCOUNT_DETAILS, {
          className: 'w-[752px]',
          closeOnClickOutside: true,
          details: {
            contractId,
            subTab: router.query.subTab,
          },
        });
        break;
      }
      case 'deposit-refund': {
        const contractId = router.query.contractId || router.query.contract;
        const requestId = router.query.requestId || router.query.request;
        if (!contractId) return;
        openFlyout(FlyoutType.DEPOSIT_REFUND, {
          className: 'w-[752px]',
          details: {
            contractId,
            requestId,
          },
        });
        break;
      }
      case 'terminate-contract': {
        const contractId = router.query.contractId || router.query.contract;
        const requestId = router.query.requestId || router.query.request;
        if (!contractId) return;
        openFlyout(FlyoutType.CONTRACT_TERMINATE, {
          className: 'w-[752px]',
          details: {
            contractId,
            requestId,
          },
        });
        break;
      }
      case 'new-claim': {
        const paymentId = router.query.paymentId || router.query.payment;
        const claimId = router.query.claimId || router.query.claim;
        openFlyout(FlyoutType.SUPER_CLAIM, {
          closeOnClickOutside: false,
          details: {
            action: ClaimAction.CREATE,
            paymentId,
            claimId,
            type: router.query.type || ClaimType.STANDARD,
          },
        });
        break;
      }
      case 'amend-claim': {
        const claimId = router.query.claimId || router.query.claim;
        openFlyout(FlyoutType.SUPER_CLAIM, {
          closeOnClickOutside: false,
          details: {
            action: ClaimAction.AMEND,
            claimId,
            type: router.query.type || ClaimType.STANDARD,
          },
        });
        break;
      }
      case 'review-claim': {
        const claimId = router.query.claimId || router.query.claim;
        openFlyout(FlyoutType.SUPER_CLAIM, {
          closeOnClickOutside: false,
          details: {
            action: ClaimAction.REVIEW,
            claimId,
            type: router.query.type || ClaimType.STANDARD,
          },
        });
        break;
      }
      case 'update-claim': {
        const claimId = router.query.claimId || router.query.claim;
        if (!claimId) return;
        openFlyout(FlyoutType.UPDATE_CLAIM, {
          className: 'w-[752px]',
          details: {
            claimId,
          },
        });
        break;
      }
      case 'earlyrelease':
      case 'get-insta-paid': {
        const claimId = router.query.claimId || router.query.claim;
        if (!claimId) return;
        openFlyout(FlyoutType.EARLY_RELEASE, {
          className: 'w-[752px]',
          details: {
            claimId,
          },
        });
        break;
      }
      case 'earlyrelease-review-offer': {
        const claimId = router.query.claimId || router.query.claim;
        if (!claimId) return;
        openFlyout(FlyoutType.EARLY_RELEASE, {
          className: 'w-[752px]',
          details: {
            isReview: true,
            claimId,
          },
        });
        break;
      }
      case 'pay-claim': {
        const claimId = router.query.claimId || router.query.claim;
        if (!claimId) return;
        openFlyout(FlyoutType.PAY_CLAIM, {
          className: 'w-[752px]',
          details: {
            claimId,
          },
        });
        break;
      }
      case 'all-claims': {
        openFlyout(FlyoutType.ALL_CLAIMS, {
          className: 'w-[752px]',
          closeOnClickOutside: true,
        });
        break;
      }
      case 'linked-payments': {
        const paymentId = router.query.paymentId || router.query.payment;
        if (!paymentId) return;
        openFlyout(FlyoutType.LINKED_PAYMENTS, {
          className: 'w-[752px]',
          closeOnClickOutside: true,
          details: {
            paymentId,
          },
        });
        break;
      }
      case 'payment-details': {
        const paymentId = router.query.paymentId || router.query.payment;
        if (!paymentId) return;
        openFlyout(FlyoutType.PAYMENT_DETAILS, {
          className: 'w-[752px]',
          closeOnClickOutside: true,
          details: {
            paymentId,
            subTab: router.query.subTab,
          },
        });
        break;
      }
      case 'claim-details': {
        const claimId = router.query.claimId || router.query.claim;
        if (!claimId) return;
        openFlyout(FlyoutType.CLAIM_DETAILS, {
          className: 'w-[752px]',
          closeOnClickOutside: true,
          details: {
            claimId,
            subTab: router.query.subTab,
          },
        });
        break;
      }
      case 'new-variation': {
        const claimMethod = upper(router.query.method);
        if (claimMethod !== ClaimMethod.PERIODIC) return;
        openFlyout(FlyoutType.NEW_VARIATION, {
          details: {
            claimMethod,
          },
        });
        break;
      }
      case 'amend-variation': {
        const claimMethod = upper(router.query.method);
        if (claimMethod !== ClaimMethod.PERIODIC) {
          closeFlyout();
          return;
        }
        const variationId = router.query.variationId || router.query.variation;
        if (!variationId) return;
        openFlyout(FlyoutType.AMEND_VARIATION, {
          details: {
            claimMethod,
            variationId,
          },
        });
        break;
      }
      case 'review-variation': {
        const claimMethod = upper(router.query.method);
        if (claimMethod !== ClaimMethod.PERIODIC) {
          closeFlyout();
          return;
        }
        const variationId = router.query.variationId || router.query.variation;
        if (!variationId) return;
        openFlyout(FlyoutType.REVIEW_VARIATION, {
          details: {
            claimMethod,
            variationId,
          },
        });
        break;
      }
      case 'all-variations': {
        openFlyout(FlyoutType.ALL_VARIATIONS, {
          className: 'w-[752px]',
          closeOnClickOutside: true,
        });
        break;
      }
      case 'variation-details': {
        const variationId = router.query.variationId || router.query.variation;
        if (!variationId) return;
        openFlyout(FlyoutType.VARIATION_DETAILS, {
          className: 'w-[752px]',
          closeOnClickOutside: true,
          details: {
            variationId,
            subTab: router.query.subTab,
          },
        });
        break;
      }
      case 'adjustment-details': {
        const adjustmentId = router.query.adjustmentId || router.query.adjustment;
        if (!adjustmentId) return;
        openFlyout(FlyoutType.ADJUSTMENT_DETAILS, {
          className: 'w-[752px]',
          closeOnClickOutside: true,
          details: {
            adjustmentId,
            subTab: router.query.subTab,
          },
        });
        break;
      }
      case 'help-center': {
        openFlyout(FlyoutType.HELP_CENTER, {
          className: 'w-[752px]',
          showCloseIcon: true,
          closeOnClickOutside: true,
        });
        break;
      }
      case 'account-transfer': {
        openFlyout(FlyoutType.ACCOUNT_TRANSFER, {
          showCloseIcon: true,
          className: 'w-[752px] flex-unset',
        });
        break;
      }
      case 'secure-payment': {
        const claimId = router.query.claimId || router.query.claim;
        const paymentId = router.query.paymentId || router.query.payment;
        if (!paymentId) return;
        openFlyout(FlyoutType.SECURE_PAYMENT, {
          className: 'w-[752px]',
          details: {
            paymentId,
            claimId,
          },
        });
        break;
      }
      case 'secure-variation': {
        const variationId = router.query.variationId || router.query.variation;
        if (!variationId) return;
        openFlyout(FlyoutType.SECURE_VARIATION, {
          className: 'w-[752px]',
          details: {
            variationId,
          },
        });
        break;
      }
      case 'secure-paid': {
        const contractId = router.query.contractId || router.query.contract;
        const requestId = router.query.requestId || router.query.request;
        if (!contractId) return;
        openFlyout(FlyoutType.SECURE_PAID_PRELOAD, {
          className: 'w-[752px]',
          details: {
            contractId,
            requestId,
          },
        });
        break;
      }
      case 'unsecure-paid': {
        const contractId = router.query.contractId || router.query.contract;
        const requestId = router.query.requestId || router.query.request;
        if (!contractId) return;
        openFlyout(FlyoutType.UNSECURE_PAID_PRELOAD, {
          className: 'w-[752px]',
          details: {
            contractId,
            requestId,
          },
        });
        break;
      }
      case 'abandon-contract': {
        openFlyout(FlyoutType.ABANDON_CONTRACT, {
          className: 'w-[752px]',
          showCloseIcon: true,
        });
        break;
      }
      case 'revoke-contract': {
        openFlyout(FlyoutType.REVOKE_CONTRACT, {
          className: 'w-[752px]',
        });
        break;
      }
      case 'set-commencement-date': {
        openFlyout(FlyoutType.SET_COMMENCEMENT_DATE, {
          className: 'w-[752px]',
        });
        break;
      }
      case 'set-completion-date': {
        openFlyout(FlyoutType.SET_COMPLETION_DATE, {
          className: 'w-[752px]',
        });
        break;
      }
      case 'new-linking': {
        const paymentId = router.query.paymentId || router.query.payment;
        if (!paymentId) return;
        openFlyout(FlyoutType.NEW_LINKING, {
          className: 'w-[752px]',
        });
        break;
      }
      case 'documents': {
        openFlyout(FlyoutType.DOCUMENTS, {
          className: 'w-[752px]',
          details: {
            subTab: router.query.subTab,
          },
        });
        break;
      }
      case 'forms': {
        openFlyout(FlyoutType.FORMS, {
          className: 'w-[752px]',
        });
        break;
      }
      default: {
        closeFlyout();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dialog,
    enableOnPlatformRetention,
    router.query.contractId,
    router.query.paymentId,
    router.query.payment,
    router.query.claimId,
    router.query.claim,
  ]);

  useEffect(() => {
    if (open || !mountedRef.current) return;
    const urlSearchParams = new URLSearchParams(window.location.search);

    urlSearchParams.delete('dialog');
    urlSearchParams.delete('t');
    urlSearchParams.delete('action');
    urlSearchParams.delete('step');
    urlSearchParams.delete('subTab');
    urlSearchParams.delete('code');
    urlSearchParams.delete('source');
    urlSearchParams.delete('draft');
    urlSearchParams.delete('relation');

    urlSearchParams.delete('contractType');

    urlSearchParams.delete('template');
    urlSearchParams.delete('contractTemplate');

    urlSearchParams.delete('method');
    urlSearchParams.delete('claimMethod');

    urlSearchParams.delete('payment');
    urlSearchParams.delete('paymentId');

    urlSearchParams.delete('requestId');
    urlSearchParams.delete('request');

    urlSearchParams.delete('claim');
    urlSearchParams.delete('claimId');

    urlSearchParams.delete('variation');
    urlSearchParams.delete('variationId');

    urlSearchParams.delete('adjustment');
    urlSearchParams.delete('adjustmentId');

    urlSearchParams.delete('transaction');
    urlSearchParams.delete('transactionId');
    urlSearchParams.delete('provisional');
    urlSearchParams.delete('participantId');
    urlSearchParams.delete('partyId');
    urlSearchParams.delete('method');
    urlSearchParams.delete('source');

    router.replace(
      {
        pathname: window.location.pathname,
        search: urlSearchParams.toString(),
      },
      undefined,
      {
        shallow: true,
        scroll: false,
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    mountedRef.current = true;
  }, []);

  return (
    <DrawerRoot open={open} onOpenChange={handleFlyoutClose}>
      <DrawerPortal>
        <DrawerOverlay />
        <DrawerContent
          fullScreen={nestedOpen}
          onEscapeKeyDown={closeOnEscapeKeyDown ? undefined : preventDefault}
          onPointerDownOutside={closeOnClickOutside ? undefined : preventDefault}
          onInteractOutside={closeOnClickOutside ? undefined : preventDefault}
          className={className}
        >
          <Suspense fallback={<LoadingIndicator />}>
            <DrawerClose hidden={!showCloseIcon} />
            <GlobalFlyoutContent type={type} details={details} />
          </Suspense>
        </DrawerContent>
      </DrawerPortal>
    </DrawerRoot>
  );
});

GlobalFlyout.displayName = 'GlobalFlyout';
