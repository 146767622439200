import { proxy } from 'valtio';
import { devtools } from 'valtio/utils';

export const globalSettingsDialogs = new Set([
  'edit-personal',
  'edit-business',
  'edit-trustees',
  'edit-individual-trustees',
  'edit-signatory',
  'revoke-contract',
]);

export enum FlyoutType {
  NONE = 'NONE',

  EDIT_PERSONAL_INFO = 'EDIT_PERSONAL_INFO',
  EDIT_BUSINESS_INFO = 'EDIT_BUSINESS_INFO',
  EDIT_TRUSTEES_INFO = 'EDIT_TRUSTEES_INFO',
  EDIT_INDIVIDUAL_TRUSTEES = 'EDIT_INDIVIDUAL_TRUSTEES',
  EDIT_SIGNATORY = 'EDIT_SIGNATORY',
  EDIT_PERMISSIONS = 'EDIT_PERMISSIONS',
  INVITE_USER = 'INVITE_USER',
  APPLY_ACCOUNT = 'APPLY_ACCOUNT',
  ACCOUNT_TRANSFER = 'ACCOUNT_TRANSFER',
  ASSIGN_FUNDS = 'ASSIGN_FUNDS',
  TRANSACTION_DETAILS = 'TRANSACTION_DETAILS',

  CREATE_PROJECT = 'CREATE_PROJECT',
  EDIT_PROJECT = 'EDIT_PROJECT',
  ARCHIVE_PROJECT = 'ARCHIVE_PROJECT',
  DELETE_PROJECT = 'DELETE_PROJECT',

  JOIN_CONTRACT = 'JOIN_CONTRACT',
  CREATE_CONTRACT = 'CREATE_CONTRACT',
  AMEND_CONTRACT = 'AMEND_CONTRACT',
  REVIEW_CONTRACT = 'REVIEW_CONTRACT',
  SIGN_CONTRACT = 'SIGN_CONTRACT',
  REVOKE_CONTRACT = 'REVOKE_CONTRACT',
  ABANDON_CONTRACT = 'ABANDON_CONTRACT',
  RESEND_INVITE = 'RESEND_INVITE',
  CONTRACT_DETAILS = 'CONTRACT_DETAILS',
  CONTRACT_NAVIGATOR = 'CONTRACT_NAVIGATOR',

  SET_COMMENCEMENT_DATE = 'SET_COMMENCEMENT_DATE',
  SET_COMPLETION_DATE = 'SET_COMPLETION_DATE',

  LINKED_PAYMENTS = 'LINKED_PAYMENTS',
  PAYMENT_DETAILS = 'PAYMENT_DETAILS',

  SUPER_CLAIM = 'SUPER_CLAIM',
  UPDATE_CLAIM = 'UPDATE_CLAIM',
  PAY_CLAIM = 'PAY_CLAIM',
  ALL_CLAIMS = 'ALL_CLAIMS',
  CLAIM_DETAILS = 'CLAIM_DETAILS',

  NEW_VARIATION = 'NEW_VARIATION',
  AMEND_VARIATION = 'AMEND_VARIATION',
  REVIEW_VARIATION = 'REVIEW_VARIATION',
  ABANDON_VARIATION = 'ABANDON_VARIATION',
  ALL_VARIATIONS = 'ALL_VARIATIONS',
  VARIATION_DETAILS = 'VARIATION_DETAILS',

  ABANDON_ADJUSTMENT = 'ABANDON_ADJUSTMENT',
  ADJUSTMENT_DETAILS = 'ADJUSTMENT_DETAILS',

  SECURE_PAYMENT = 'SECURE_PAYMENT',
  SECURE_VARIATION = 'SECURE_VARIATION',
  EARLY_RELEASE = 'EARLY_RELEASE',

  ACCOUNT_DETAILS = 'ACCOUNT_DETAILS',
  DEPOSIT_REFUND = 'DEPOSIT_REFUND',
  CONTRACT_TERMINATE = 'CONTRACT_TERMINATE',

  HELP_CENTER = 'HELP_CENTER',
  CHECKOUT = 'CHECKOUT',

  RETENTION_CLAIM_DETAILS = 'RETENTION_CLAIM_DETAILS',

  NEW_LINKING = 'NEW_LINKING',

  SUPER_CONTRACT = 'SUPER_CONTRACT',

  PROJECT_GUIDE = 'PROJECT_GUIDE',

  DOCUMENTS = 'DOCUMENTS',
  FORMS = 'FORMS',

  SECURE_PAID_PRELOAD = 'SECURE_PAID_PRELOAD',
  UNSECURE_PAID_PRELOAD = 'UNSECURE_PAID_PRELOAD',
}

export enum NestedFlyoutType {
  NONE = 'NONE',

  VERIFY_MOBILE = 'VERIFY_MOBILE',

  EDIT_TRUSTEE = 'EDIT_TRUSTEE',
  EDIT_PERSONAL = 'EDIT_PERSONAL',
  EDIT_BUSINESS = 'EDIT_BUSINESS',
  EDIT_INDIVIDUAL_TRUSTEE = 'EDIT_INDIVIDUAL_TRUSTEE',
  EDIT_SIGNATORY = 'EDIT_SIGNATORY',

  COMPLETE_PERSONAL = 'COMPLETE_PERSONAL',
  COMPLETE_BUSINESS = 'COMPLETE_BUSINESS',
  COMPLETE_TRUSTEES = 'COMPLETE_TRUSTEES',

  SECUREPAID_DOC = 'SECUREPAID_DOC',
  EVIDENCE_MAP_VIEW = 'EVIDENCE_MAP_VIEW',
  RETENTION_CLAIM_DETAILS = 'RETENTION_CLAIM_DETAILS',
  CLAIM_DETAILS_WITH_RETENTION = 'CLAIM_DETAILS_WITH_RETENTION',

  REVOKE_CONTRACT = 'REVOKE_CONTRACT',
}

export type FlyoutState = {
  type: FlyoutType;
  pathname: string;
  search?: string;
  data?: Record<string, unknown> | null;
};

export const initialState = {
  open: false,
  className: '',
  showCloseIcon: false,
  nestedOpen: false,
  nestedType: NestedFlyoutType.NONE,
  nestedDetails: {} as Record<string, any>,
  type: FlyoutType.NONE,
  closeOnClickOutside: false,
  closeOnEscapeKeyDown: true,
  stack: [] as FlyoutState[],
  details: {} as Record<string, any>,
  closeCallback: () => {},
};

export const flyoutManager = proxy(initialState);

devtools(flyoutManager, {
  name: 'Global Flyout',
  enabled: false,
});
